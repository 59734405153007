// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppDevDomainNameQueryVariables = Types.Exact<{
  fullName: Types.Scalars['String']['input'];
}>;


export type AppDevDomainNameQuery = { __typename?: 'RootQuery', app: { __typename?: 'AppQuery', byFullName: { __typename?: 'App', devDomainName?: { __typename?: 'AppDevDomainName', name: string } | null } } };


export const AppDevDomainNameDocument = gql`
    query AppDevDomainName($fullName: String!) {
  app {
    byFullName(fullName: $fullName) {
      devDomainName {
        name
      }
    }
  }
}
    `;
export function useAppDevDomainNameQuery(baseOptions: Apollo.QueryHookOptions<AppDevDomainNameQuery, AppDevDomainNameQueryVariables> & ({ variables: AppDevDomainNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppDevDomainNameQuery, AppDevDomainNameQueryVariables>(AppDevDomainNameDocument, options);
      }
export function useAppDevDomainNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppDevDomainNameQuery, AppDevDomainNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppDevDomainNameQuery, AppDevDomainNameQueryVariables>(AppDevDomainNameDocument, options);
        }
export function useAppDevDomainNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AppDevDomainNameQuery, AppDevDomainNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppDevDomainNameQuery, AppDevDomainNameQueryVariables>(AppDevDomainNameDocument, options);
        }
export type AppDevDomainNameQueryHookResult = ReturnType<typeof useAppDevDomainNameQuery>;
export type AppDevDomainNameLazyQueryHookResult = ReturnType<typeof useAppDevDomainNameLazyQuery>;
export type AppDevDomainNameSuspenseQueryHookResult = ReturnType<typeof useAppDevDomainNameSuspenseQuery>;
export type AppDevDomainNameQueryResult = Apollo.QueryResult<AppDevDomainNameQuery, AppDevDomainNameQueryVariables>;
export function refetchAppDevDomainNameQuery(variables: AppDevDomainNameQueryVariables) {
      return { query: AppDevDomainNameDocument, variables: variables }
    }