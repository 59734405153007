export enum FeatureGateKey {
  STRIPE_KILLSWITCH = 'stripe-killswitch',
  EAS_INSIGHTS_ENABLE_DEPLOYMENT_INSIGHTS = 'eas-insights-enable-deployment-insights',
  ENABLE_NOTIFICATION_FCM_V1_CREDENTIALS_FEATURE_GATE = 'enable-notification-fcm-v1-credentials-feature-gate',
  ENABLE_NOTIFICATIONS_USAGE_UI = 'enable-notifications-usage-ui',
  PROJECT_CONTENT_BROWSER = 'project-content-browser',
  SERVERLESS_DEPLOYMENTS = 'serverless-deployments',
  ENVIRONMENT_VARIABLES = 'environment-variables',
  RUNTIMES_CONCEPT = 'runtimes-concept',
  USER_AUDIT_LOG = 'user-audit-log',
}
