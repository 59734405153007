import type { Endpoints, RequestMethod } from '@octokit/types';
import invariant from 'invariant';

import * as Analytics from '~/common/analytics';
import {
  AppPlatform,
  GitHubBuildTriggerExecutionBehavior,
  GitHubBuildTriggerType,
} from '~/graphql/types.generated';
import { useCreateGitHubBuildTriggerMutation } from '~/scenes/ProjectGitHubBotSettingsScene/queries/CreateGitHubBuildTrigger.mutation.generated';
import {
  refetchGetGitHubRepoAndSettingsQuery,
  useGetGitHubRepoAndSettingsLazyQuery,
} from '~/scenes/ProjectGitHubBotSettingsScene/queries/GetGitHubRepoAndSettings.query.generated';
import { useUpdateGitHubBuildTriggerMutation } from '~/scenes/ProjectGitHubBotSettingsScene/queries/UpdateGitHubBuildTrigger.mutation.generated';
import { InlineLink } from '~/ui/components/InlineLink';

export type IdentifyingGitHubAppInstallationData = {
  id: number;
  account: {
    login: string;
    id: number;
    avatarUrl: string;
    type: string;
    authenticatedUserIsAdmin: boolean;
  };
};

export function getGitCommitValue(
  gitCommitHash?: string | null,
  githubRepositoryUrl?: string | null,
  isGitWorkingTreeDirty?: boolean | null
) {
  if (!gitCommitHash) {
    return;
  }

  const gitCommitText = `${gitCommitHash.slice(0, 7)}${isGitWorkingTreeDirty ? '*' : ''}`;

  if (githubRepositoryUrl) {
    return (
      <InlineLink
        onClick={() => {
          Analytics.track(Analytics.events.GITHUB_COMMIT_LINK_CLICKED);
        }}
        className="border-b-0"
        size="span"
        type="external"
        href={`${githubRepositoryUrl}/commit/${gitCommitHash}`}
        text={gitCommitText}
      />
    );
  }

  return gitCommitText;
}

async function fetchGitHubAPIAsync<Endpoint extends keyof Endpoints>(
  method: RequestMethod,
  endpoint: string,
  accessToken: string,
  options: {
    headers: Record<string, string>;
    params?: Endpoints[Endpoint]['parameters'];
    noCache?: boolean;
  }
): Promise<Endpoints[Endpoint]['response']['data']> {
  const url = new URL(`https://api.github.com/${endpoint}`);
  if (method === 'GET' && options.params) {
    Object.keys(options.params).forEach((key) => {
      if (options.params && options.params[key as keyof typeof options.params]) {
        url.searchParams.append(key, String(options.params[key as keyof typeof options.params]));
      }
    });
  }

  const response = await fetch(url, {
    headers: {
      ...(options.noCache ? { 'If-None-Match': '' } : {}), // note(Juwan): https://github.com/octokit/octokit.js/issues/890#issuecomment-392193948
      ...options.headers,
      Accept: 'application/vnd.github+json',
      Authorization: `Bearer ${accessToken}`,
      'X-GitHub-Api-Version': '2022-11-28',
    },
    method,
    body: method === 'GET' ? undefined : JSON.stringify(options.params),
  });

  return await response.json();
}

async function getAllGitHubAppInstallationsForAuthenticatedUserAsync(
  accessToken: string
): Promise<Endpoints['GET /user/installations']['response']['data']['installations']> {
  const allInstallations: Endpoints['GET /user/installations']['response']['data']['installations'] =
    [];
  let page = 1;
  let installationsForAuthenticatedGitHubUserResponse;
  do {
    installationsForAuthenticatedGitHubUserResponse =
      await fetchGitHubAPIAsync<'GET /user/installations'>(
        'GET',
        'user/installations',
        accessToken,
        {
          headers: {},
          params: { per_page: 100, page },
          noCache: true,
        }
      );

    allInstallations.push(...installationsForAuthenticatedGitHubUserResponse.installations);
    page++;
  } while (installationsForAuthenticatedGitHubUserResponse.installations.length === 100);

  return allInstallations;
}

async function getAllGitHubOrgsForAuthenticatedUserAsync(
  accessToken: string
): Promise<Endpoints['GET /user/memberships/orgs']['response']['data']> {
  const allOrgs: Endpoints['GET /user/memberships/orgs']['response']['data'] = [];
  let page = 1;
  let orgsForAuthenticatedGitHubUserResponse;
  do {
    orgsForAuthenticatedGitHubUserResponse =
      await fetchGitHubAPIAsync<'GET /user/memberships/orgs'>(
        'GET',
        'user/memberships/orgs',
        accessToken,
        {
          headers: {},
          params: { per_page: 100, page },
          noCache: true,
        }
      );
    allOrgs.push(...orgsForAuthenticatedGitHubUserResponse);
    page++;
  } while (orgsForAuthenticatedGitHubUserResponse.length === 100);

  return allOrgs;
}

// keep in sync with www/src/external/GitHub/GitHubUtils.ts
export async function getGitHubAppInstallationsForAuthenticatedUserAsync(
  accessToken: string
): Promise<IdentifyingGitHubAppInstallationData[]> {
  const authenticatedGitHubUserResponse = await fetchGitHubAPIAsync<'GET /user'>(
    'GET',
    'user',
    accessToken,
    { headers: {} }
  );

  const user = authenticatedGitHubUserResponse;

  const [installations, orgs] = await Promise.all([
    // this diverges from the WWW implementation since Octokit seems to be breaking the website for
    // older browsers
    getAllGitHubAppInstallationsForAuthenticatedUserAsync(accessToken),
    getAllGitHubOrgsForAuthenticatedUserAsync(accessToken),
  ]);

  // note(Juwan): filter out installations that are not owned by the user or the user is not a member of the
  // organization
  // this prevents people who are "collaborators" on individual repos on other accounts from linking
  // accounts they don't own/are not a member of
  const filteredInstallations: IdentifyingGitHubAppInstallationData[] = [];

  installations.forEach((installation) => {
    const account = installation.account;

    invariant(account, 'Expected installation metadata to return account data');

    // note(Juwan): check that the account is not an enterprise account by looking for `login`
    if ('login' in account) {
      if (account.login === user.login) {
        filteredInstallations.push({
          ...installation,
          account: {
            login: account.login,
            id: account.id,
            avatarUrl: account.avatar_url,
            type: account.type,
            authenticatedUserIsAdmin: true,
          },
        });
      } else if (account.type === 'Organization') {
        const org = orgs.find((org) => org.organization.login === account.login);
        if (org) {
          filteredInstallations.push({
            ...installation,
            account: {
              login: account.login,
              id: account.id,
              avatarUrl: account.avatar_url,
              type: account.type,
              authenticatedUserIsAdmin: org.role === 'admin',
            },
          });
        }
      }
    }
  });

  return filteredInstallations;
}

export async function getGitHubRepositoriesForAuthenticatedUserAndInstallationAsync({
  accessToken,
  installationId,
  perPage,
  page,
}: {
  accessToken: string;
  installationId: number;
  perPage: number;
  page: number;
}): Promise<
  Endpoints['GET /user/installations/{installation_id}/repositories']['response']['data']
> {
  return await fetchGitHubAPIAsync<'GET /user/installations/{installation_id}/repositories'>(
    'GET',
    `user/installations/${installationId}/repositories`,
    accessToken,
    {
      headers: {},
      params: { per_page: perPage, page, installation_id: installationId },
    }
  );
}

export async function getAllGitHubRepositoriesAuthenticatedUserAndInstallationAsync({
  accessToken,
  installationId,
}: {
  accessToken: string;
  installationId: number;
}): Promise<
  Endpoints['GET /user/installations/{installation_id}/repositories']['response']['data']['repositories']
> {
  const allRepos: Endpoints['GET /user/installations/{installation_id}/repositories']['response']['data']['repositories'] =
    [];
  let page = 1;
  let reposForAuthenticatedGitHubUserResponse;
  do {
    reposForAuthenticatedGitHubUserResponse =
      await getGitHubRepositoriesForAuthenticatedUserAndInstallationAsync({
        accessToken,
        installationId,
        perPage: 100,
        page,
      });

    allRepos.push(...reposForAuthenticatedGitHubUserResponse.repositories);
    page++;
  } while (reposForAuthenticatedGitHubUserResponse.repositories.length === 100);

  return allRepos;
}

export function useConfigureGitHubBuildTriggersForNewProject() {
  const [createGitHubBuildTriggerAsync] = useCreateGitHubBuildTriggerMutation();

  async function configureGitHubBuildTriggersForNewProjectAsync({
    fullName,
    appId,
  }: {
    fullName: string;
    appId: string;
  }) {
    const platforms = [AppPlatform.Android, AppPlatform.Ios];

    const triggersToCreate = [
      {
        type: GitHubBuildTriggerType.PushToBranch,
        sourcePattern: 'main',
        buildProfile: 'production',
      },
      {
        type: GitHubBuildTriggerType.PullRequestUpdated,
        sourcePattern: '*',
        targetPattern: '*',
        buildProfile: 'development',
      },
    ];

    await Promise.all(
      platforms.map((platform) =>
        Promise.all(
          triggersToCreate.map((trigger) =>
            createGitHubBuildTriggerAsync({
              variables: {
                githubBuildTriggerData: {
                  appId,
                  isActive: false,
                  type: trigger.type,
                  sourcePattern: trigger.sourcePattern,
                  buildProfile: trigger.buildProfile,
                  platform,
                  autoSubmit: false,
                  submitProfile: trigger.buildProfile,
                  targetPattern: trigger.targetPattern ?? null,
                  executionBehavior: GitHubBuildTriggerExecutionBehavior.BaseDirectoryChanged,
                },
              },
              refetchQueries: [
                refetchGetGitHubRepoAndSettingsQuery({
                  fullName,
                }),
              ],
              awaitRefetchQueries: true,
            })
          )
        )
      )
    );
  }

  return { configureGitHubBuildTriggersForNewProjectAsync };
}

export function useEnableGitHubBuildTriggersForProject(platform: string) {
  const [getGitHubRepoAndSettingsAsync] = useGetGitHubRepoAndSettingsLazyQuery();
  const [updateGitHubBuildTriggerAsync] = useUpdateGitHubBuildTriggerMutation();

  async function enableGitHubBuildTriggersForProjectAsync({ fullName }: { fullName: string }) {
    if (!['android', 'ios'].includes(platform.toLowerCase())) {
      return;
    }

    const { data, error } = await getGitHubRepoAndSettingsAsync({
      variables: { fullName },
      fetchPolicy: 'network-only',
    });

    const githubBuildTriggers = data?.app.byFullName.githubBuildTriggers;

    if (!githubBuildTriggers?.length) {
      if (error) {
        console.error(error);
      }
      return;
    }

    await Promise.all(
      githubBuildTriggers
        // only enable triggers for the specified platform since we only build for one platform at a
        // time during onboarding
        .filter((trigger) => trigger.platform.toLowerCase() === platform.toLowerCase())
        .map(async (trigger) => {
          await updateGitHubBuildTriggerAsync({
            variables: {
              githubBuildTriggerId: trigger.id,
              githubBuildTriggerData: {
                autoSubmit: trigger.autoSubmit,
                buildProfile: trigger.buildProfile,
                platform: trigger.platform,
                sourcePattern: trigger.sourcePattern,
                type: trigger.type,
                submitProfile: trigger.submitProfile ?? null,
                targetPattern: trigger.targetPattern ?? null,
                isActive: true,
                executionBehavior: trigger.executionBehavior,
              },
            },
          });
        })
    );
  }

  return { enableGitHubBuildTriggersForProjectAsync };
}
