import FeatureGate from '~/common/gating/FeatureGate';
import { FeatureGateKey } from '~/common/gating/FeatureGateKey';
import FeatureGateOverrides, { FeatureGateQueryParams } from '~/common/gating/FeatureGateOverrides';
import { RuntimesConceptFeatureGate } from '~/common/gating/gates/RuntimesConceptFeatureGate';
import { ServerlessDeploymentsFeatureGate } from '~/common/gating/gates/ServerlessDeploymentsFeatureGate';
import { UserAuditLogFeatureGate } from '~/common/gating/gates/UserAuditLogFeatureGate';
import { PageProps } from '~/scenes/_app/helpers';

import { EASInsightsEnableDeploymentInsights } from './gates/EASInsightsEnableDeploymentInsights';
import { EnableNotificationFcmV1CredentialsFeatureGate } from './gates/EnableNotificationFcmV1CredentialsFeatureGate';
import { EnableNotificationsUsageUI } from './gates/EnableNotificationsUsageUI';
import { EnvironmentVariablesFeatureGate } from './gates/EnvironmentVariablesFeatureGate';
import { ProjectContentBrowserFeatureGate } from './gates/ProjectContentBrowserFeatureGate';
import { StripeKillswitchFeatureGate } from './gates/StripeKillswitchFeatureGate';

export function getFeatureGates(
  currentUser: PageProps['currentUser'],
  featureGateQueryParams: FeatureGateQueryParams
): Record<FeatureGateKey, FeatureGate> {
  const featureGateServerValues = currentUser?.featureGates ?? {};
  const featureGateAccountIds = currentUser?.accounts.map((account) => account.id) ?? [];
  const featureGateOverrides = new FeatureGateOverrides(featureGateQueryParams);

  return {
    [FeatureGateKey.EAS_INSIGHTS_ENABLE_DEPLOYMENT_INSIGHTS]:
      new EASInsightsEnableDeploymentInsights(
        featureGateServerValues[FeatureGateKey.EAS_INSIGHTS_ENABLE_DEPLOYMENT_INSIGHTS],
        featureGateOverrides,
        {
          accountIds: featureGateAccountIds,
        }
      ),
    [FeatureGateKey.ENABLE_NOTIFICATION_FCM_V1_CREDENTIALS_FEATURE_GATE]:
      new EnableNotificationFcmV1CredentialsFeatureGate(
        featureGateServerValues[FeatureGateKey.ENABLE_NOTIFICATION_FCM_V1_CREDENTIALS_FEATURE_GATE],
        featureGateOverrides,
        {
          accountIds: featureGateAccountIds,
        }
      ),
    [FeatureGateKey.ENABLE_NOTIFICATIONS_USAGE_UI]: new EnableNotificationsUsageUI(
      featureGateServerValues[FeatureGateKey.ENABLE_NOTIFICATIONS_USAGE_UI],
      featureGateOverrides,
      {
        accountIds: featureGateAccountIds,
      }
    ),
    [FeatureGateKey.STRIPE_KILLSWITCH]: new StripeKillswitchFeatureGate(
      featureGateServerValues[FeatureGateKey.STRIPE_KILLSWITCH],
      featureGateOverrides
    ),
    [FeatureGateKey.PROJECT_CONTENT_BROWSER]: new ProjectContentBrowserFeatureGate(
      featureGateServerValues[FeatureGateKey.PROJECT_CONTENT_BROWSER],
      featureGateOverrides
    ),
    [FeatureGateKey.SERVERLESS_DEPLOYMENTS]: new ServerlessDeploymentsFeatureGate(
      featureGateServerValues[FeatureGateKey.SERVERLESS_DEPLOYMENTS],
      featureGateOverrides
    ),
    [FeatureGateKey.ENVIRONMENT_VARIABLES]: new EnvironmentVariablesFeatureGate(
      featureGateServerValues[FeatureGateKey.ENVIRONMENT_VARIABLES],
      featureGateOverrides
    ),
    [FeatureGateKey.RUNTIMES_CONCEPT]: new RuntimesConceptFeatureGate(
      featureGateServerValues[FeatureGateKey.RUNTIMES_CONCEPT],
      featureGateOverrides
    ),
    [FeatureGateKey.USER_AUDIT_LOG]: new UserAuditLogFeatureGate(
      featureGateServerValues[FeatureGateKey.USER_AUDIT_LOG],
      featureGateOverrides
    ),
  };
}
