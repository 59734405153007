export const flags = {
  APPLE_BANNED_QR_CODES_FROM_IOS_APP: false,
};

export const externalApis = {
  statusPage: 'https://status.expo.dev/api/v2/status.json',
};

export const links = {
  itunes: 'https://itunes.apple.com/app/apple-store/id982107779',
  play: 'https://play.google.com/store/apps/details?id=host.exp.exponent&referrer=www',
};

export const developmentInfoLogs = [
  'Are you new to developing Expo.dev? Check out our write-ups in Notion: https://www.notion.so/expo/expo-io-0fb863417fa5485290263cd870058eaf',
  'Are you unsure about how to use a UI component? View our component library here: https://github.com/expo/styleguide',
];

// https://date-fns.org/v2.11.0/docs/format
export const dateFnsFormats = {
  logTimestamp: 'MMM dd HH:mm:ss',
  timestamp: 'MMM d, yyyy h:mm a',
  timestampWithFullMonth: 'MMMM d, yyyy h:mm a',
  extendedTimestamp: 'EEEE, MMM d, yyyy h:mm a',
  date: 'MMM d, yyyy',
  dateNoDay: 'MMM yyyy',
  dateWithFullMonth: 'MMMM d, yyyy',
  dateWithoutYear: 'MMM d',
  dateWithDay: 'EEEE MMM d, yyyy',
  dayOfMonth: 'do',
  timeOfDay: 'h:mm a',
  dateInputFormat: 'MM/dd/yyyy',
};
